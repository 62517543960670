import React from "react";
import Gantt from "../../Components/Gantt";
import { getClienteByCedula, getProductosByCedula } from "../../Helpers/ServicioPagos";



let taskssss = [
    {
        start: new Date(2020, 1, 1),
        end: new Date(2020, 1, 2),
        name: 'Idea',
        id: 'Task 0',
        type: 'task',
        progress: 45,
        isDisabled: true,
        styles: { progressColor: '#ffbb54', progressSelectedColor: '#ff9e0d' },
    }
    
];

function getTask(id, start, end, name, dependencies) {
    return {
        start: start,
        end: end,
        name: name,
        id: id,
        dependencies: dependencies,

        type: 'task',
        progress: 45,
        isDisabled: true,
        styles: { progressColor: '#ffbb54', progressSelectedColor: '#ff9e0d' },
    }
}


export default function Clients({ idClient }) {
    const [client, setClient] = React.useState([]);
    const [productos, setProductos] = React.useState([]);

    const [tasks, setTasks] = React.useState(taskssss);


   
    React.useEffect(
        function () {
            console.log('idClient:' + idClient);
            getClienteByCedula(idClient).then((x) => setClient(x.data.content));
        },
        [idClient]
    );

    
    React.useEffect(
        function () {
            console.log('Client:', client);
            setTasks([...tasks, getTask(
                'ClientId:'  + idClient,
                new Date(2020, 1, 1),
                new Date(2020, 1, 2),
                client.nombres,[]
            )])

            getProductosByCedula(idClient).then((x) => setProductos(x.data.content));
        },
        [client]
    );


    React.useEffect(
        function () {
            console.log('Productos:', productos);
            
        },
        [productos]
    );





    


    return (
        <div className="App" style={{ height: "100%", width: "100%" }}>

<Gantt items={tasks} />
        </div>
    );
}
