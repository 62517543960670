import axios from 'axios';

export const isAsyncOperationCanceled = err => axios.isCancel(err);
export const isUnauthorizedStatusCode = error => error && error.response && error.response.status === 401;

const rejectHttpError = error => {
    if (error.response) {
        return Promise.reject(error.response.data);
    } else if (error.request) {
        return Promise.reject(error.request);
    } else {
        return Promise.reject(error.message);
    }
};

const handleUnauthorizedResponse = () => {
    //saveInLocalStorage('token', null);
    //window.location = '/login';
    //throw Error('Debe iniciar sesion');
};

export const doGET = (url, token) => {
    try {
        return axios
            .get(url)
            .then(resp => resp.data)
            .catch(error => {
                if (isUnauthorizedStatusCode(error)) {
                    handleUnauthorizedResponse(error);
                } else {
                    return rejectHttpError(error);
                }
            });
    } catch (e) {
        if (!isAsyncOperationCanceled(token)) {
            console.error('promise canceled');
            Promise.reject(e);
        }
    }
};