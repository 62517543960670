
import { Gantt as GanttComponent, Task, EventOption, StylingOption, ViewMode, DisplayOption } from 'gantt-task-react';
import "gantt-task-react/dist/index.css";



export default function Gantt({ items }) {

    return (
        <GanttComponent tasks={items} />
    );
}
