import App from "../../App";
import { BrowserRouter, Navigate, Route, Routes, useParams } from "react-router-dom";
import Clients from "../../Pages/Clients";
import Client from "../../Pages/Client";
import Lab from "../../Lab";


export default function Router() {
    return (
        <BrowserRouter>
        <Routes>
          <Route path="clients" element={<Clients />} />
          <Route path="clients/:idClient" element={<Client idClient={134619} />} />
          <Route path="home" element={<App />} />
          <Route path="*" element={<Lab />} />
        </Routes>
      </BrowserRouter>
    );
  }


