import { getClienteByCedula, getClienteProductosFacturasPagos, getClienteProductosFacturasPagosByCedula, getClientes, getFacturasByIdPago, getFacturasByIdProducto, getPagosByCedula, getPagosByIdFactura, getProductosByCedula } from "../Helpers/ServicioPagos";
import React from 'react';
import PagosTimeLine from '../Components/PagosTimeLine';
import { InputCedula } from '../Components/Input/Cedula';
import ClientTimeLine from '../Components/ClientTimeLine';
import Cliente from '../Components/Cliente';

import { DataGrid } from '@mui/x-data-grid';

const rows = [
  { id: 1, col1: 'Hello', col2: 'World' },
  { id: 2, col1: 'DataGridPro', col2: 'is Awesome' },
  { id: 3, col1: 'MUI', col2: 'is Amazing' },
];

const columns = [
  { field: 'col1', headerName: 'Column 1', width: 150 },
  { field: 'col2', headerName: 'Column 2', width: 150 },
];


export default function Lab() {
  console.clear();
  if(false)
  {
  getClientes().then(x => console.warn('Clientes:', x.content));
  getClienteByCedula(2584426).then(x => console.warn('Cliente:', x.content));
  getProductosByCedula(2584426).then(x => console.warn('Productos:', x.content));
  getPagosByCedula(2584426).then(x => console.warn('Pagos:', x.content));
  getFacturasByIdProducto(178364).then(x => console.warn('Facturas:', x.content));
  getPagosByIdFactura(2423278).then(x => console.warn('Pagos By Factura:', x.content));
  getFacturasByIdPago(668305).then(x => console.warn('Facturas By Pago:', x.content));
}
else
  getClienteProductosFacturasPagosByCedula(3506234).then(x => console.warn('ClienteFull:', x));

  
  const [idCliente, setIdCliente] = React.useState(null);

  function handleOnValid(cedula) {
    console.log(cedula);
    //setIdCliente(cedula.slice(0, -1));
    //console.log(getProductos(cedula.slice(0, -1)));
    console.warn(getClientes());
  }

return (<>LabMode</>)
  return (
    <div>
      LabMode
      <InputCedula onValid={handleOnValid} value='27115917'></InputCedula>
      <Cliente idCliente={idCliente}></Cliente>
      <ClientTimeLine idCliente={idCliente} />



    </div>
  );
}