import { API_SERVICIO_PAGOS } from './Config';
import { doGET } from './Service';

export const getClientes = function() {    
    return doGET(API_SERVICIO_PAGOS + '/api/v1/Cliente/');
}

export const getClienteByCedula = function(cedula) {    
    return doGET(API_SERVICIO_PAGOS + '/api/v1/Cliente/' + cedula);
}

export const getPagosByCedula = function(cedula) {    
    return doGET(API_SERVICIO_PAGOS + '/api/v1/Cliente/' + cedula + '/pagos');
}

export const getProductosByCedula = function(idCliente) {    
    return doGET(API_SERVICIO_PAGOS + '/api/v1/Producto/' + idCliente);
}

export const getFacturasByIdProducto = function(idProducto) {    
    return doGET(API_SERVICIO_PAGOS + '/api/v1/Factura/producto/' + idProducto) ;
}

export const getPagosByIdFactura = function(idFactura) {    
    return doGET(API_SERVICIO_PAGOS + '/api/v1/Factura/pago/' + idFactura) ;
}

export const getFacturasByIdPago = function(idPago) {    
    return doGET(API_SERVICIO_PAGOS + '/api/v1/Factura/Facturas/' + idPago) ;
}

export const getEmisores = function() {    
    return doGET(API_SERVICIO_PAGOS + '/api/v1/Emisor');
}









export const getClienteProductosFacturasPagosByCedula = async function(cedula)
{
    const cliente = (await getClienteByCedula(cedula)).content || {};
    cliente.productos = (await getProductosFacturasPagosByCedula(cedula)) || [];
    return cliente;
}

export const getProductosFacturasPagosByCedula = async function(cedula)
{
    const productos = (await getProductosByCedula(cedula)).content || [];

    for (var i = 0; i<productos.length; i++)
    {
        productos[i].facturas = (await getFacturasPagosByIdProducto(productos[i].id)) || []
    }

    return productos;
}

export const getFacturasPagosByIdProducto = async function(idProducto)
{

    const facturas = (await getFacturasByIdProducto(idProducto)).content || [];

    for (var i = 0; i<facturas.length; i++)
    {
        facturas[i].pagos =(await getPagosByIdFactura(facturas[i].id)).content || [];
        if(facturas[i].pagos.length>0)
        debugger;
    }

    return facturas;
}

