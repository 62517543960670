import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import Gantt from "../../Components/Gantt";
import { getClientes } from "../../Helpers/ServicioPagos";

const columns = [
  { field: "id", headerName: "Id" },
  { field: "cedula", headerName: "Documento" },
  { field: "nombre", headerName: "Nombres" },
  { field: "apellido", headerName: "Apellidos" },
  { field: "email", headerName: "E-Mail" },
];

export default function Clients() {
  const [clients, setClients] = React.useState([]);

  React.useEffect(
    function () {
      console.log(clients);
    },
    [clients]
  );

  React.useEffect(function () {
    getClientes().then((x) => setClients(x.data.content));
  }, []);

  return (
    <div className="App" style={{ height: "100%", width: "100%" }}>
      <div style={{ height: 300, width: "100%" }}>
        <DataGrid rows={clients} columns={columns} />
      </div>
      <Gantt />
    </div>
  );
}
